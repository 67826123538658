<template>
  <v-dialog
    v-model="visibility"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card id="to-print">
      <v-card-title class="header-print">
        <v-container class="pa-0">
        <img src="@/assets/images/logo-dark.svg" alt="" />
        </v-container>
      </v-card-title>
      <v-card-text>
        <v-container>
        <v-row class="my-5 d-print-none">
          <v-btn plain color="primary" @click="$emit('close_print')"
            ><v-icon left>mdi-arrow-left</v-icon>{{ $t("btn.back") }}</v-btn
          >
        </v-row>

        <v-row style="color: #000000" no-gutters justify="space-between" class="text-h4 mb-2">
          {{ selectedItem.name }}
        </v-row>

        <v-divider v-if="selectedItem.body.length" />
        <div class="my-4" v-html="selectedItem.body"></div>
        <v-divider v-if="selectedItem.body.length" />
        <v-row v-if="permissions.can_view_document_author" no-gutters>
          <v-spacer />
          <div>Автор:&nbsp;</div>
          <div class="font-weight-bold">
            {{ selectedItem.user_details.name }}&nbsp;{{
              selectedItem.user_details.surname
            }}
          </div>
        </v-row>
        <v-row class="text-caption mt-1" no-gutters>
          <v-spacer />
          <div>
            {{ $t("documentation.version") }}
          </div>
          <div>
            {{ selectedItem.version_major }}.{{ selectedItem.version_minor }}
          </div>
        </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import user from "@/mixins/user";

export default {
  mixins: [user],
  props: {
    visible: {
      require: true,
    },
    selectedItem: {
      require: true,
    },
  },
  mounted() {
    setTimeout(() => {
      window.print();
      window.onafterprint = this.$emit("close_print");
    }, 1000);
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close_print");
      },
    },
  },
  watch: {
    selectedItem() {
      window.print();
    },
  },
};
</script>

<style>
.header-print {
  width: 100%;
  background-color: #f3f2f2;
  border-bottom: 1px solid #e6e6e6;
  padding: 10px 0;
}

@media print {
  .v-dialog__content.v-dialog__content--active {
    position: inherit;
  }
  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }
  .container {
    padding: 10px 20px !important;
  }
  #to-print {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }
}
</style>